.titles{
  text-align: right;
  margin: 2px !important;
  text-transform: uppercase;
  font-weight: bolder !important;
  color: #0A368B !important;
}

.images{
  border-radius: 20px;
  width: 40vw;
  
  margin: 5vw;
}

img.images{
  height: 40vw;
}

.inlineM{
  display: inline-flex;
}
.imagebottom{
  width: 300px;
  margin: 80px 10%;
  height: 100%;
}
@media screen and (max-width: 900px) {
  .titles{
    font-size: 1.5em !important;
  }

}

@media screen and (max-width: 600px) {
  .titles{
    font-size: 1.2em !important;
  }
  .inlineM{
    display: block;
  }
  .images{
    border-radius: 20px;
    width: 80vw;
    
    margin: 5vw;
  }
  img.images{
    height: 80vw;
  }
  .imagebottom{
    width: 200px;
    margin: 50px auto;
    height: 100%;

  }

}
